import Proptypes from "prop-types";
import React from "react";

const HospitalIcon = ({ width = 41, height = 40 }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="hospital">
      <g id="&#240;&#159;&#145;&#129;&#239;&#184;&#143; Masquer si besoin - D&#195;&#169;corative">
        <path
          id="Path"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.75 6C6.4743 6 6.25 5.7757 6.25 5.5C6.25 5.22428 6.4743 5 6.75 5C7.0257 5 7.25 5.22428 7.25 5.5C7.25 5.7757 7.0257 6 6.75 6Z"
          fill="#CACAFB"
        />
        <path
          id="Path_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.75 38C10.4743 38 10.25 37.7757 10.25 37.5C10.25 37.2243 10.4743 37 10.75 37C11.0257 37 11.25 37.2243 11.25 37.5C11.25 37.7757 11.0257 38 10.75 38Z"
          fill="#CACAFB"
        />
        <path
          id="Path_3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.75 28C38.4743 28 38.25 27.7757 38.25 27.5C38.25 27.2243 38.4743 27 38.75 27C39.0257 27 39.25 27.2243 39.25 27.5C39.25 27.7757 39.0257 28 38.75 28Z"
          fill="#CACAFB"
        />
        <path
          id="Path_4"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.75 3C29.4743 3 29.25 2.7757 29.25 2.5C29.25 2.22428 29.4743 2 29.75 2C30.0257 2 30.25 2.22428 30.25 2.5C30.25 2.7757 30.0257 3 29.75 3Z"
          fill="#CACAFB"
        />
      </g>
      <g id="&#240;&#159;&#142;&#168; Mineure (indice -main)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.25 25H24.25C24.5261 25 24.75 25.2239 24.75 25.5V27.5C24.75 27.7761 24.5261 28 24.25 28H16.25C15.9739 28 15.75 27.7761 15.75 27.5V25.5C15.75 25.2239 15.9739 25 16.25 25ZM23.75 27V26H16.75V27H23.75Z"
          fill="#E1000F"
        />
        <path
          d="M23.25 27C23.5064 27 23.7178 27.193 23.7466 27.4417L23.75 27.5V33.5C23.75 33.7761 23.5261 34 23.25 34C22.9936 34 22.7822 33.807 22.7534 33.5583L22.75 33.5V28H17.75V33.5C17.75 33.7564 17.557 33.9678 17.3083 33.9966L17.25 34C16.9936 34 16.7822 33.807 16.7534 33.5583L16.75 33.5V27.5C16.75 27.2436 16.943 27.0322 17.1917 27.0034L17.25 27H23.25Z"
          fill="#E1000F"
        />
        <path
          d="M20.25 31C20.5064 31 20.7178 31.193 20.7466 31.4417L20.75 31.5V33.5C20.75 33.7761 20.5261 34 20.25 34C19.9936 34 19.7822 33.807 19.7534 33.5583L19.75 33.5V31.5C19.75 31.2239 19.9739 31 20.25 31Z"
          fill="#E1000F"
        />
      </g>
      <g id="Majeure">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.25 6H29.25C29.5261 6 29.75 6.22386 29.75 6.5V8.5C29.75 8.77614 29.5261 9 29.25 9H11.25C10.9739 9 10.75 8.77614 10.75 8.5V6.5C10.75 6.22386 10.9739 6 11.25 6ZM28.75 8V7H11.75V8H28.75Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.25 8H27.25C27.5261 8 27.75 8.22386 27.75 8.5V33.5C27.75 33.7761 27.5261 34 27.25 34H13.25C12.9739 34 12.75 33.7761 12.75 33.5V8.5C12.75 8.22386 12.9739 8 13.25 8ZM26.75 33V9H13.75V33H26.75Z"
          fill="#000091"
        />
        <path
          d="M13.25 16C13.5064 16 13.7178 16.193 13.7466 16.4417L13.75 16.5V33.5C13.75 33.7564 13.557 33.9678 13.3083 33.9966L13.25 34H6.25C5.99358 34 5.78225 33.807 5.75336 33.5583L5.75 33.5V30.5C5.75 30.2239 5.97386 30 6.25 30C6.50642 30 6.71775 30.193 6.74664 30.4417L6.75 30.5V33H12.75V17H6.75V26.5C6.75 26.7564 6.55698 26.9678 6.30831 26.9966L6.25 27C5.99358 27 5.78225 26.807 5.75336 26.5583L5.75 26.5V16.5C5.75 16.2436 5.94302 16.0322 6.19169 16.0034L6.25 16H13.25Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.25 14H13.25C13.5261 14 13.75 14.2239 13.75 14.5V16.5C13.75 16.7761 13.5261 17 13.25 17H5.25C4.97386 17 4.75 16.7761 4.75 16.5V14.5C4.75 14.2239 4.97386 14 5.25 14ZM12.75 16V15H5.75V16H12.75Z"
          fill="#000091"
        />
        <path
          d="M34.25 16C34.5064 16 34.7178 16.193 34.7466 16.4417L34.75 16.5V22.5C34.75 22.7761 34.5261 23 34.25 23C33.9936 23 33.7822 22.807 33.7534 22.5583L33.75 22.5V17H27.75V33H33.75V27.5C33.75 27.2436 33.943 27.0322 34.1917 27.0034L34.25 27C34.5064 27 34.7178 27.193 34.7466 27.4417L34.75 27.5V33.5C34.75 33.7564 34.557 33.9678 34.3083 33.9966L34.25 34H27.25C26.9936 34 26.7822 33.807 26.7534 33.5583L26.75 33.5V16.5C26.75 16.2436 26.943 16.0322 27.1917 16.0034L27.25 16H34.25Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.25 14H35.25C35.5261 14 35.75 14.2239 35.75 14.5V16.5C35.75 16.7761 35.5261 17 35.25 17H27.25C26.9739 17 26.75 16.7761 26.75 16.5V14.5C26.75 14.2239 26.9739 14 27.25 14ZM34.75 16V15H27.75V16H34.75Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 18H11.25C11.5261 18 11.75 18.2239 11.75 18.5V22.5C11.75 22.7761 11.5261 23 11.25 23H8.25C7.97386 23 7.75 22.7761 7.75 22.5V18.5C7.75 18.2239 7.97386 18 8.25 18ZM10.75 22V19H8.75V22H10.75Z"
          fill="#000091"
        />
        <path
          d="M11.25 27C11.5261 27 11.75 27.2239 11.75 27.5C11.75 27.7564 11.557 27.9678 11.3083 27.9966L11.25 28H8.75C8.47386 28 8.25 27.7761 8.25 27.5C8.25 27.2436 8.44302 27.0322 8.69169 27.0034L8.75 27H11.25Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.25 18H32.25C32.5261 18 32.75 18.2239 32.75 18.5V22.5C32.75 22.7761 32.5261 23 32.25 23H29.25C28.9739 23 28.75 22.7761 28.75 22.5V18.5C28.75 18.2239 28.9739 18 29.25 18ZM31.75 22V19H29.75V22H31.75Z"
          fill="#000091"
        />
        <path
          d="M32.25 27C32.5261 27 32.75 27.2239 32.75 27.5C32.75 27.7564 32.557 27.9678 32.3083 27.9966L32.25 28H29.75C29.4739 28 29.25 27.7761 29.25 27.5C29.25 27.2436 29.443 27.0322 29.6917 27.0034L29.75 27H32.25Z"
          fill="#000091"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.1917 12.0034L19.25 12H21.25C21.5261 12 21.75 12.2239 21.75 12.5V14H23.25C23.5064 14 23.7178 14.193 23.7466 14.4417L23.75 14.5V16.5C23.75 16.7761 23.5261 17 23.25 17H21.75V18.5C21.75 18.7564 21.557 18.9678 21.3083 18.9966L21.25 19H19.25C18.9739 19 18.75 18.7761 18.75 18.5V17H17.25C16.9936 17 16.7822 16.807 16.7534 16.5583L16.75 16.5V14.5C16.75 14.2239 16.9739 14 17.25 14H18.75V12.5C18.75 12.2436 18.943 12.0322 19.1917 12.0034ZM20.75 14.5V13H19.75V14.5C19.75 14.7564 19.557 14.9678 19.3083 14.9966L19.25 15H17.75V16H19.25C19.5064 16 19.7178 16.193 19.7466 16.4417L19.75 16.5V18H20.75V16.5C20.75 16.2436 20.943 16.0322 21.1917 16.0034L21.25 16H22.75V15H21.25C20.9936 15 20.7822 14.807 20.7534 14.5583L20.75 14.5Z"
          fill="#000091"
        />
        <path
          d="M23.25 22C23.5261 22 23.75 22.2239 23.75 22.5C23.75 22.7564 23.557 22.9678 23.3083 22.9966L23.25 23H17.25C16.9739 23 16.75 22.7761 16.75 22.5C16.75 22.2436 16.943 22.0322 17.1917 22.0034L17.25 22H23.25Z"
          fill="#000091"
        />
      </g>
    </g>
  </svg>
);

HospitalIcon.propTypes = {
  height: Proptypes.number,
  width: Proptypes.number,
};

export default HospitalIcon;
