import Proptypes from "prop-types";
import React from "react";

const Calendar = ({ size = 40 }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5 12C38.2243 12 38 11.7757 38 11.5C38 11.2243 38.2243 11 38.5 11C38.7757 11 39 11.2243 39 11.5C39 11.7757 38.7757 12 38.5 12Z"
      fill="#CACAFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5 40C14.2243 40 14 39.7757 14 39.5C14 39.2243 14.2243 39 14.5 39C14.7757 39 15 39.2243 15 39.5C15 39.7757 14.7757 40 14.5 40Z"
      fill="#CACAFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 5C7.2243 5 7 4.7757 7 4.5C7 4.22428 7.2243 4 7.5 4C7.7757 4 8 4.22428 8 4.5C8 4.7757 7.7757 5 7.5 5Z"
      fill="#CACAFB"
    />
    <path
      d="M34.7761 34.6629C32.2009 36.7483 28.4227 36.3512 26.3373 33.7759C24.2519 31.2007 24.649 27.4225 27.2243 25.3371C29.7995 23.2517 33.5777 23.6488 35.6631 26.2241C35.8369 26.4387 35.8038 26.7535 35.5892 26.9273C35.3746 27.1011 35.0597 27.068 34.8859 26.8534C33.1481 24.7074 29.9996 24.3765 27.8536 26.1143C25.7076 27.8521 25.3766 31.0006 27.1145 33.1466C28.8523 35.2926 32.0008 35.6236 34.1468 33.8857C35.5105 32.7814 36.1787 31.0683 35.9597 29.3593C35.9247 29.0854 36.1182 28.8349 36.3921 28.7998C36.666 28.7647 36.9165 28.9583 36.9516 29.2322C37.2142 31.2816 36.4121 33.3381 34.7761 34.6629Z"
      fill="#E1000F"
    />
    <path
      d="M29.0208 29.9282L30.1857 31.3055L33.2196 27.7544C33.4212 27.5184 33.7727 27.4985 33.9983 27.7112C34.2018 27.9031 34.2326 28.2201 34.0663 28.4592L34.0394 28.4936L30.7552 32.3382C30.4862 32.6532 30.0128 32.6904 29.6979 32.4214C29.6671 32.3951 29.6385 32.3664 29.6124 32.3355L28.1976 30.6634C28.0034 30.4338 28.0228 30.0862 28.2424 29.8812C28.4534 29.6841 28.7759 29.6879 28.9912 29.8961L29.0208 29.9282Z"
      fill="#E1000F"
    />
    <path
      d="M11.5 5C11.7564 5 11.9678 5.19302 11.9966 5.44169L12 5.5V7H28V5.5C28 5.22386 28.2239 5 28.5 5C28.7564 5 28.9678 5.19302 28.9966 5.44169L29 5.5V7H32.5C33.8444 7 34.9409 8.06115 34.9977 9.39155L35 9.5V22.5C35 22.7761 34.7761 23 34.5 23C34.2436 23 34.0322 22.807 34.0034 22.5583L34 22.5V14H31.5C31.2239 14 31 13.7761 31 13.5C31 13.2436 31.193 13.0322 31.4417 13.0034L31.5 13H34V9.5C34 8.70116 33.3755 8.04817 32.5881 8.00255L32.5 8H29V9.5C29 9.77614 28.7761 10 28.5 10C28.2436 10 28.0322 9.80698 28.0034 9.55831L28 9.5V8H12V9.5C12 9.77614 11.7761 10 11.5 10C11.2436 10 11.0322 9.80698 11.0034 9.55831L11 9.5V8H7.5C6.70116 8 6.04817 8.62446 6.00255 9.41186L6 9.5V13H11V12.5C11 12.2239 11.2239 12 11.5 12C11.7564 12 11.9678 12.193 11.9966 12.4417L12 12.5V13H28.5C28.7761 13 29 13.2239 29 13.5C29 13.7564 28.807 13.9678 28.5583 13.9966L28.5 14H12V14.5C12 14.7761 11.7761 15 11.5 15C11.2436 15 11.0322 14.807 11.0034 14.5583L11 14.5V14H6V30.5C6 31.2988 6.62446 31.9518 7.41186 31.9975L7.5 32H21.5C21.7761 32 22 32.2239 22 32.5C22 32.7564 21.807 32.9678 21.5583 32.9966L21.5 33H7.5C6.15562 33 5.05909 31.9388 5.00231 30.6084L5 30.5V9.5C5 8.15562 6.06115 7.05909 7.39156 7.00231L7.5 7H11V5.5C11 5.22386 11.2239 5 11.5 5Z"
      fill="#000091"
    />
    <path
      d="M23.5 32C23.2239 32 23 32.2239 23 32.5C23 32.7761 23.2239 33 23.5 33C23.7761 33 24 32.7761 24 32.5C24 32.2239 23.7761 32 23.5 32Z"
      fill="#000091"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 18.5C15 17.6716 14.3284 17 13.5 17H10.5C9.67157 17 9 17.6716 9 18.5V20.5C9 21.3284 9.67157 22 10.5 22H13.5C14.3284 22 15 21.3284 15 20.5V18.5ZM10 18.5C10 18.2239 10.2239 18 10.5 18H13.5C13.7761 18 14 18.2239 14 18.5V20.5C14 20.7761 13.7761 21 13.5 21H10.5C10.2239 21 10 20.7761 10 20.5V18.5Z"
      fill="#000091"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.5 17C30.3284 17 31 17.6716 31 18.5V20.5C31 21.3284 30.3284 22 29.5 22H26.5C25.6716 22 25 21.3284 25 20.5V18.5C25 17.6716 25.6716 17 26.5 17H29.5ZM26.5 18C26.2239 18 26 18.2239 26 18.5V20.5C26 20.7761 26.2239 21 26.5 21H29.5C29.7761 21 30 20.7761 30 20.5V18.5C30 18.2239 29.7761 18 29.5 18H26.5Z"
      fill="#000091"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 17H18.5C17.6716 17 17 17.6716 17 18.5V20.5C17 21.3284 17.6716 22 18.5 22H21.5C22.3284 22 23 21.3284 23 20.5V18.5C23 17.6716 22.3284 17 21.5 17ZM18.5 18H21.5C21.7761 18 22 18.2239 22 18.5V20.5C22 20.7761 21.7761 21 21.5 21H18.5C18.2239 21 18 20.7761 18 20.5V18.5C18 18.2239 18.2239 18 18.5 18Z"
      fill="#000091"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 24C14.3284 24 15 24.6716 15 25.5V27.5C15 28.3284 14.3284 29 13.5 29H10.5C9.67157 29 9 28.3284 9 27.5V25.5C9 24.6716 9.67157 24 10.5 24H13.5ZM10.5 25C10.2239 25 10 25.2239 10 25.5V27.5C10 27.7761 10.2239 28 10.5 28H13.5C13.7761 28 14 27.7761 14 27.5V25.5C14 25.2239 13.7761 25 13.5 25H10.5Z"
      fill="#000091"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.5 24H18.5C17.6716 24 17 24.6716 17 25.5V27.5C17 28.3284 17.6716 29 18.5 29H21.5C22.3284 29 23 28.3284 23 27.5V25.5C23 24.6716 22.3284 24 21.5 24ZM18.5 25H21.5C21.7761 25 22 25.2239 22 25.5V27.5C22 27.7761 21.7761 28 21.5 28H18.5C18.2239 28 18 27.7761 18 27.5V25.5C18 25.2239 18.2239 25 18.5 25Z"
      fill="#000091"
    />
  </svg>
);

Calendar.propTypes = {
  size: Proptypes.number,
};

export default Calendar;
