import "./maintenance.scss";

import React from "react";

const Maintenance = () => {
  return (
    <div className="maintenance">
      <div>
        Ce site est actuellement en maintenance. Veuillez réessayer
        ultérieurement.
      </div>
    </div>
  );
};

export default Maintenance;
