import Proptypes from "prop-types";
import React from "react";

const Warning = ({ size = 40 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34 6.5C33.7243 6.5 33.5 6.2757 33.5 6C33.5 5.72428 33.7243 5.5 34 5.5C34.2757 5.5 34.5 5.72428 34.5 6C34.5 6.2757 34.2757 6.5 34 6.5Z"
      fill="#CACAFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5 38C20.2243 38 20 37.7757 20 37.5C20 37.2243 20.2243 37 20.5 37C20.7757 37 21 37.2243 21 37.5C21 37.7757 20.7757 38 20.5 38Z"
      fill="#CACAFB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 5C7.2243 5 7 4.7757 7 4.5C7 4.22428 7.2243 4 7.5 4C7.7757 4 8 4.22428 8 4.5C8 4.7757 7.7757 5 7.5 5Z"
      fill="#CACAFB"
    />
    <path
      d="M21 17C21 16.4477 20.5523 16 20 16C19.4477 16 19 16.4477 19 17V25C19 25.5523 19.4477 26 20 26C20.5523 26 21 25.5523 21 25V17Z"
      fill="#E1000F"
    />
    <path
      d="M21 29C21 28.4477 20.5523 28 20 28C19.4477 28 19 28.4477 19 29C19 29.5523 19.4477 30 20 30C20.5523 30 21 29.5523 21 29Z"
      fill="#E1000F"
    />
    <path
      d="M19.5529 6.27639C19.7275 5.92726 20.2087 5.90889 20.4155 6.22127L20.4474 6.27639L33.9474 33.2764C34.1048 33.5913 33.8962 33.959 33.5577 33.9968L33.5002 34H29.5002C29.224 34 29.0002 33.7761 29.0002 33.5C29.0002 33.2436 29.1932 33.0322 29.4418 33.0034L29.5002 33H32.6907L20.0002 7.618L7.30916 33H26.5002C26.7566 33 26.9679 33.193 26.9968 33.4417L27.0002 33.5C27.0002 33.7564 26.8071 33.9678 26.5585 33.9966L26.5002 34H6.50016C6.14803 34 5.91253 33.6489 6.03005 33.3293L6.05294 33.2764L19.5529 6.27639Z"
      fill="#000091"
    />
    <path
      d="M30.0002 23C29.7245 23 29.5002 22.7757 29.5002 22.5C29.5002 22.2243 29.7245 22 30.0002 22C30.2759 22 30.5002 22.2243 30.5002 22.5C30.5002 22.7757 30.2759 23 30.0002 23Z"
      fill="#000091"
    />
    <path
      d="M31.4183 24.2257C31.2812 24.0162 31.0059 23.9381 30.7766 24.0528C30.5296 24.1763 30.4294 24.4766 30.5529 24.7236L34.0529 31.7236L34.082 31.7743C34.2191 31.9838 34.4944 32.0619 34.7238 31.9472C34.9708 31.8237 35.0709 31.5234 34.9474 31.2764L31.4474 24.2764L31.4183 24.2257Z"
      fill="#000091"
    />
  </svg>
);

Warning.propTypes = {
  size: Proptypes.number,
};

export default Warning;
